/*

    Name:       material
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original material color scheme by Mattia Astorino (https://github.com/equinusocio/material-theme)

*/

.cm-s-material {
  background-color: #263238;
  color: rgba(233, 237, 237, 1);
}
.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: rgb(83,127,126);
  border: none;
}
.cm-s-material .CodeMirror-guttermarker, .cm-s-material .CodeMirror-guttermarker-subtle, .cm-s-material .CodeMirror-linenumber {
  color: rgb(83,127,126);
}
.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #f8f8f0 !important;
}
.cm-s-material .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.15);
}
.cm-s-material.CodeMirror-focused .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.10);
}
.cm-s-material .CodeMirror-line::selection, .cm-s-material .CodeMirror-line > span::selection, .cm-s-material .CodeMirror-line > span > span::selection {
  background: rgba(255, 255, 255, 0.10);
}
.cm-s-material .CodeMirror-line::-moz-selection, .cm-s-material .CodeMirror-line > span::-moz-selection, .cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: rgba(255, 255, 255, 0.10);
}

.CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0) !important;
}
.cm-s-material span.cm-keyword {
  color: rgba(199, 146, 234, 1);
}
.cm-s-material span.cm-operator {
  color: rgba(233, 237, 237, 1);
}
.cm-s-material span.cm-variable-2 {
  color: #80CBC4;
}
.cm-s-material span.cm-variable-3 {
  color: #82B1FF;
}
.cm-s-material span.cm-builtin {
  color: #DECB6B;
}
.cm-s-material span.cm-atom {
  color: #F77669;
}
.cm-s-material span.cm-number {
  color: #F77669;
}
.cm-s-material span.cm-def {
  color: rgba(233, 237, 237, 1);
}
.cm-s-material span.cm-error {
  color: rgba(255, 255, 255, 1.0);
  background-color: #EC5F67;
}
.cm-s-material span.cm-string {
  color: #C3E88D;
}
.cm-s-material span.cm-string-2 {
  color: #80CBC4;
}
.cm-s-material span.cm-comment {
  color: #546E7A;
}
.cm-s-material span.cm-variable {
  color: #82B1FF;
}
.cm-s-material span.cm-tag {
  color: #80CBC4;
}
.cm-s-material span.cm-meta{
  color: #80CBC4;
}
.cm-s-material span.cm-attribute {
  color: #FFCB6B;
}
.cm-s-material span.cm-property {
  color: #80CBAE;
}
.cm-s-material span.cm-qualifier {
  color: #DECB6B;
}
.cm-s-material span.cm-variable-3{
  color: #DECB6B;
}
.cm-s-material span.cm-tag {
  color: rgba(255, 83, 112, 1);
}
.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}